(function(){

  var $btn = $('.js-m-toggle');
  var $menu = $('.js-mobilenav')

  $btn.on('click', toggleMenu);

  function toggleMenu(e){
    e.preventDefault();
    $menu.toggleClass('isOpen');
    $(this).toggleClass('isToggled');
    $('html').toggleClass('menuIsOpen');
  }

})();

(function(){

  var $btn = $('.js-subnav-toggle');
  var $menu = $('.js-subnav-list')

  $btn.on('click', toggleMenu);

  function toggleMenu(e){
    e.preventDefault();
    $menu.slideToggle();
  }

})();
