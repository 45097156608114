(function() {

  var cookie = $('.js-cookie-notice');

  if (JSON.parse(sessionStorage.getItem('cookie-notice')) !== false) {
    cookie.addClass('isVisible');
  }

  $('.js-cookie-close').on('click', function(e) {
    e.preventDefault();
    cookie.removeClass('isVisible');
    sessionStorage.setItem('cookie-notice', JSON.stringify(false));
  });

})();
