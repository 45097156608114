(function ($) {

   //Handle the contact form
        var contactform = $('#main-contact-form');
        var target = Routing.generate('site_frontend_ajax_call');
        contactform.submit(function(e) {
                e.preventDefault();
                var form_status = $('<div class="form_status"></div>');
                $.ajax({
                    type: contactform.attr('method'),
                    url: target,
                    beforeSend: function(){
              				$('#submit').addClass('isLoading');
              			},
                    data: {
                        entity: 'contact',
                        form: contactform.serializeArray()
                    },
                    success: function(data) {
                        if(data.status == 'ok'){
                            $('#submit').removeClass('isLoading');
                            $('#submit').addClass('loadingSuccess');
                            setTimeout(function(){
                              $('#submit').removeClass('loadingSuccess');
                            }, 3000);
                            contactform[0].reset();
                        }
                        else {
                            contactform.append(form_status.html('<p class="text-error">' + data.message + '</p>').delay(3000).fadeOut());
                        }
                    }
                });
        });

})(jQuery);
