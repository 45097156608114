var slick = require('slick-carousel');

(function(){

  $('.js-slider').on('init', function(){
    $('.hero').addClass('slickIsLoaded');
  });

  $('.js-slider').slick({
    arrows: false,
    dots: true,
    appendDots: $('.hero__dots')
  });

  $('.js-gallery').slick({
    arrows: false,
    dots: true,
    fade: true
  });

  $('.js-service-slider').slick({
    mobileFirst: true,
    nextArrow: '<div class="slick__arrow slick__arrow--next"></div>',
    prevArrow: '<div class="slick__arrow slick__arrow--prev"></div>',
  	responsive: [{
  		breakpoint: 992,
  	  settings: {
        slidesToShow: 5
      }
  	}]
  });

  $('.js-eyecatcher-slider').slick({
    arrows: false,
    dots: true,
    appendDots: $('.eyecatchers__dots')
  });

  $('.js-team-slider').slick({
    mobileFirst: true,
    nextArrow: '<div class="slick__arrow slick__arrow--black slick__arrow--next"></div>',
    prevArrow: '<div class="slick__arrow slick__arrow--black slick__arrow--prev"></div>',
  	responsive: [{
  		breakpoint: 992,
  	  settings: 'unslick'
  	}]
  });


})();
