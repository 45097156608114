(function(){

  function toggleBox(){
    $(this).siblings('.js-acc-box').slideToggle();
    $(this).children('.acc__btn').toggleClass('isOpen');
  };

  var toggle = $('.js-acc-heading');
  toggle.on('click', toggleBox);


})();
